<template>
  <section>
    <b-form-group label="Tipo de Calculo">
      <b-form-select
        v-model="typeCalc"
        :options="optionsTypeCalc"           
        size="sm"
        class="mt-3"
      ></b-form-select>
    </b-form-group>

    <b-form-checkbox
      class="mb-3"
      v-model="ajustarPeriodo"
      name="check-button"
      switch 
    >
      Realizar calculo com mais de 60 meses?
    </b-form-checkbox>

    <b-form-checkbox 
        class="mb-3"
        v-model="cutManad"
        name="check-button"
        switch
      >
        Deseja cortar periodo do manad ?
    </b-form-checkbox>            
    <div>       
    </div>
  </section>
</template>

<script>
// import emitter from "@/services/emitter";
export default {
  name: "ImportManadForm",
  data() {
    return {
      typeCalc: 1,
      optionsTypeCalc: [
        { value: 1, text: "1º Analise" },
        { value: 2, text: "2º Analise" },
        { value: 3, text: "3º Analise" },
      ],
      ajustarPeriodo: false,
      cutManad: false,
      publicSector: false,  
    };
  },

  watch: {
    typeCalc(newValue) {
      if (newValue) this.$emit("showUploadComponent", true);
      return this.$emit("dataImportManadForm", this.dataListImporManad());
    },

    ajustarPeriodo() {
      return this.$emit("dataImportManadForm", this.dataListImporManad());
    },

    cutManad() {      
      return this.$emit("dataImportManadForm", this.dataListImporManad());
    },

    chooseCategoryPublicSector(newValue) {
      if (!newValue) {
        this.categoryOptionsSelected = [];
        // this.selectAllCategory();
      }
    },

    categoryOptionsSelected() {
      return this.$emit("dataImportManadForm", this.dataListImporManad());
    },

    cutManadPeriod(newValue) {
      if (newValue === "years") {
        this.listPeriodCutManad = [];
      } else {
        this.listYearsCutManad = [];
      }

      return this.$emit("dataImportManadForm", this.dataListImporManad());
    },

    listYearsCutManad() {
      return this.$emit("dataImportManadForm", this.dataListImporManad());
    },

    listPeriodCutManad() {
      return this.$emit("dataImportManadForm", this.dataListImporManad());
    },
  },

  mounted() {
    // this.selectAllCategory();
  },

  methods: {
    clearForm(item) {
      if (item.years) {
        this.period.years = "";
        return (this.period.months = []);
      }

      return (this.yearCutManad = "");
    },

    dataListImporManad() {
      return {
        typeCalc: this.typeCalc,
        ajustarPeriodo: this.ajustarPeriodo,
        cutManad: this.cutManad,
        // ajustarPeriodo: true,
        // cutManad: true,
        publicSector: this.publicSector,
        categoryOptionsSelected: this.categoryOptionsSelected,
        cutManadPeriod: this.cutManadPeriod,
        listYearsCutManad: this.listYearsCutManad,
        listPeriodCutManad: this.listPeriodCutManad,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
</style>