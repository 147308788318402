<template>
  <section>
    <b-overlay :show="showOverlay" rounded="sm">
      <div v-if="isLoaded">
        <div class="">
          <div class="align-items-center mb-4">
            <h4 class="text-center text-secondary">Upload dos arquivos.</h4>
          </div>
          <b-overlay
            :show="loadingSpinner"
            rounded="sm"
            opacity="0.6"
            spinner-small
            spinner-variant="secondary"
            class="d-inline-block w-100"
          >
            <VueDropzone
              id="uploadComponent"
              class="vue-dropzone"
              ref="UploadComponentDropzone"
              :options="dropzoneOptions"
              @vdropzone-success="handleUploadSuccess"
              @vdropzone-error="handleUploadError"
              @vdropzone-file-added="handleFileAdded"
              @vdropzone-queue-complete="handleCompletedQueue"
              @vdropzone-sending="sendingEvent"
              :useCustomSlot="true"
            >
              <div class="dropzone-custom-content">
                <h3 class="dropzone-custom-title">
                  Arraste e solte aqui seus arquivos.
                </h3>
                <div class="subtitle">
                  ... ou clique para abrir o diretório.
                </div>
              </div>
            </VueDropzone>
          </b-overlay>
        </div>

        <ListInssUploadFiles
          v-if="hasFilesSended"
          :listFiles="filesSuccessAdded"
          :nCodOp="nCodOp"
          @showOverlay="showOverlay = $event"
          @cleanList="loadFileList()"
        />
      </div>
      <div v-else>
        <div class="p-5 d-flex justify-content-center">
          <h1 class="text-secondary d-flex align-items-end">
            <span class="mx-3">Carregando</span>
            <DotAnimation />
          </h1>
        </div>
      </div>
    </b-overlay>

    <!-- START - MODAL CORTA MANAD -->
    <b-modal ref="CortarManad" size="xl" id="modal-exclusao-periodo" scrollable>
      <b-tabs class="titleColor">
        <b-tab active class="pt-4">
          <template #title>
            Periodos
            <span class="badge badge-pill badge-primary">
              <span class="sr-only">Cortar Manad</span>
            </span>
          </template>
          <b-overlay :show="tablebusyCut">
            <table class="table table-striped table-hover">
              <thead class="bg-dark text-light">
                <tr>
                  <th class="w-100 d-flex justify-content-between">
                    <span class="w-50 text-center">Adicionados</span>
                    <span class="w-50 text-center">Removidos</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style="cursor: pointer !important" class="w-100">
                  <div class="d-flex justify-content-between w-100">
                    <div class="w-50">
                      <span
                        class="d-block w-100 text-center"
                        v-for="(
                          item, index
                        ) in periodosAddOrRemove.listAdicionados"
                        :key="index"
                        @click.stop="addCorteToList(item)"
                      >
                        {{ index + 1 }} - {{ item }}
                      </span>
                    </div>
                    <div class="w-50">
                      <span
                        class="d-block w-100 text-center"
                        v-for="(
                          item, index
                        ) in periodosAddOrRemove.listRemovidos"
                        :key="index"
                        @click.stop="removeCorteToList(item)"
                      >
                        {{ item }}
                      </span>
                    </div>
                  </div>
                </tr>
              </tbody>
            </table>
          </b-overlay>
        </b-tab>
      </b-tabs>

      <template #modal-footer>
        <div class="w-100">
          <button class="btn btn-success" @click="sendPeriodManad()">
            Enviar
          </button>
          <b-button
            variant="dark"
            size="sm"
            class="float-right"
            @click="show = false"
          >
            Close
          </b-button>
        </div>
      </template>
    </b-modal>
    <!-- END -  MODAL CORTA MANAD -->
  </section>
</template>

<script>
import DotAnimation from "@/Components/Utils/DotAnimation.vue";
import HelperService from "@/services/helper.service.js";
import ListUploadFiles from "@/Components/UploadComponent/ListUploadFiles.vue";
import UploadInssService from "@/services/uploadInss.service.js";
import Vue2Dropzone, { data } from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import Vue from "vue";
import { CLOSING } from "ws";
import inssService from "../../services/teses/inss.service";
import ListInssUploadFiles from "@/Components/UploadComponent/ListInssUploadFiles.vue";

export default {
  name: "UploadComponentInss",
  components: {
    DotAnimation,
    ListUploadFiles,
    VueDropzone: Vue2Dropzone,
    ListInssUploadFiles,
  },

  data() {
    return {
      showOverlay: false,
      loadingSpinner: false,
      isLoaded: false,
      tese: "",
      teseAcceptedFiles: "",
      nCodOp: "",
      isCompletedWithoutErrors: "",
      hasFilesSended: false,
      filesSuccessAdded: [],
      isOpen: false,
      toOpenList: {
        file: [],
        response: [],
      },
      yearsAdded: [],
      dropzoneOptions: {},
      cortaManad: [],
      tablebusyCut: false,
      listPeriodoremoved: [],
      periodosAddOrRemove: {
        listRemovidos: [],
        listAdicionados: [],
      },
      FileUpload: "",
      boxUpload: "",
    };
  },

  props: { paramsImportFiles: Object, openModal: Boolean },

  mounted() {
    this.validateParamsImportFiles();
    this.isLoaded = false;
    this.tese = this.$route.fullPath.split("/")[2];
    this.nCodOp = this.$route.params.nCodOp;
    this.loadFileList();
    this.dropzoneOptions = {
      url: `${process.env.VUE_APP_URL_API}/${this.tese}/upload/`,
      addRemoveLinks: false,
      duplicateCheck: true,
      parallelUploads: 1,
      autoProcessQueue: true,
      acceptedFiles: ".txt",
      paramName: "files",
      timeout: 0,
      maxFiles: 100,
    };
    switch (this.tese) {
      case "restituicao":
      case "inss":
        this.dropzoneOptions.acceptedFiles = ".txt, .zip";
        break;
      case "icms":
        this.teseAcceptedFiles = [".txt, .zip"];
        break;
    }
    return this.dropzoneOptions;
  },

  watch: {
    paramsImportFiles() {
      this.paramsImportFiles.NCodOp = this.$route.params.nCodOp;
      this.dropzoneOptions.params = {
        NCodOp: this.nCodOp,
        AjustarPeriodo: this.paramsImportFiles.ajustarPeriodo,
        ValidaPrefeitura: false,
        IsRPPS: false,
        CutManad: this.paramsImportFiles.cutManad,
        periodos: this.cortaManad,
      };
    },

    openModal(newValue) {
      if (newValue) this.$refs["CortarManad"].show();
      this.$emit("resetOpenModal", false);
    },
  },

  methods: {
    async loadFileList() {
      this.loadingSpinner = true;

      // await inssService.checkFilesINSS(this.nCodOp).then((response) => {
      //   var error = response.data.dados
      //   console.log("error",error)
      //   if(error){
      //     // this.isOpen = true;
      //     this.$swal({
      //       icon: "info",
      //       title: "Aviso",
      //       html: `Este Código ja esta em fase de calculo.`,
      //       type: "warning",
      //       showCancelButton: true,
      //       confirmButtonColor: "#3085d6",
      //       cancelButtonColor: "#d33",
      //       confirmButtonText: "Ir para Calculo",
      //       cancelButtonText: `Voltar para inicio`,
      //     }).then((result) => {
      //       console.log('response',result.isConfirmed)

      //       if(result.isConfirmed){
      //         console.log("isConfirmed", result.isConfirmed)
      //         return this.$router.push(`/manadRubricaPesquisa/` + this.nCodOp)
      //       }
      //       return this.$router.push(`/home`)
      //     }
      //     )
      //   }
      // })

      await UploadInssService.getFilesSended(this.nCodOp)
        .then((response) => {
          // this.loadingSpinner = false;
          if (response.status == 200) {
            this.filesSuccessAdded = response.data.result.dados;
            this.isLoaded = true;
          }
          this.hasFilesSended = this.filesSuccessAdded.length > 0;
        })
        .catch((error) => {
          // return HelperService.callMakeToast(error, "error");
          this.$toast.open({ message: error, type: "error", duration: 4000 });
        });
      this.loadingSpinner = false;
    },

    sendingEvent(file, xhr, formData) {
      // const teste = this.$refs.UploadComponentDropzone.getAcceptedFiles();
      formData.append("NcodOp", this.nCodOp);
      formData.append("AjustarPeriodo", this.paramsImportFiles.ajustarPeriodo);
      formData.append("ValidaPrefeitura", false);
      formData.append("CutManad", this.paramsImportFiles.cutManad);
      formData.append("periodos", this.cortaManad);
      // formData.append('files', file);
    },

    loadPasso2() {
      this.loadingSpinner = true;
      this.$emit("passo2");
    },

    handleUploadSuccess(file, response) {
      console.log("response", response);
      this.FileUpload = file;
      try {
        this.loadingSpinner = false;
        // let filename = file.upload.filename.split("-")[3].trim();
        let filename = this.FileUpload.upload.filename;
        // let year = filename.slice(0, 7);
        this.$refs.UploadComponentDropzone.removeFile(file);
        // let message = "Arquivo de " + year + " enviado com sucesso";
        // let message = "Arquivo enviado com sucesso";
        let message = "Arquivo de " + filename + " enviado com sucesso";
        this.loadFileList();
        // return HelperService.callMakeToast(message, "success");
        this.$toast.open({ message: message, type: "success", duration: 2500 });
      } catch (error) {
        console.error("error ->", error);
        // return HelperService.callMakeToast(error, "error");
        this.$toast.open({ message: error, type: "error", duration: 4000 });
      }
      if (response.mensagem.length > 0) {
        response.mensagem.forEach((element) => {
          this.$toast.open({ message: element, type: "error", duration: 4000 });
        });
      }
    },

    addCorteToList(item) {
      this.periodosAddOrRemove.listAdicionados.splice(
        this.periodosAddOrRemove.listAdicionados.indexOf(item),
        1
      );
      this.periodosAddOrRemove.listRemovidos.push(item);
    },

    removeCorteToList(item) {
      this.periodosAddOrRemove.listRemovidos.splice(
        this.periodosAddOrRemove.listRemovidos.indexOf(item),
        1
      );
      this.periodosAddOrRemove.listAdicionados.push(item);
    },

    handleUploadError(file, response, xhr) {
      this.FileUpload = file;
      var error = "Erro ao enviar";
      // let listPeriodos;
      if (response.mensagem != null) {
        error = response.mensagem[0];
        // listPeriodos = response.dados;
        // this.cortaManad = response.dados;
        this.periodosAddOrRemove.listAdicionados = response.dados;
        this.cortaManad = this.periodosAddOrRemove.listAdicionados;
        this.tablebusyCut = true;
      }

      //TODO: validar qual é o tipo de erro de forma mais específica
      // if (response.dados != null && response.dados.length == 2) {
      if (error == "isPrefeitura") {
        if (this.isOpen) {
          this.toOpenList.file.push(file);
          this.toOpenList.response.push(response);
        } else {
          this.isOpen = true;
          let filename = file.upload.filename;
          // let year = "2022";
          this.$swal({
            icon: "info",
            title: "Orgão Publico",
            html: `Usar base de cálculo para contribuição do RPPS ?`,
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim",
            cancelButtonText: `Não! Vou usar a base de cálculo normal.`,
          }).then((result) => {
            if (result.value) {
              this.loadingSpinner = true;
              this.$refs.UploadComponentDropzone.removeFile(file);
              this.$refs.UploadComponentDropzone.manuallyAddFile(file, "");
              this.callModalIfExits();
              UploadInssService.uploadFiles(
                file,
                this.tese,
                this.nCodOp,
                this.paramsImportFiles.AjustarPeriodo, // caso queira calcular periodo maior que 60 meses
                true, //valida prefeitura
                true, //  defini se o calculo é RPPS  quando clicar em SIM
                this.paramsImportFiles.CutManad, //verifica se é para cortar manad ou nao
                this.cortaManad // lista de periodos para calcula
              )
                .then((response) => {
                  this.loadingSpinner = false;
                  if (response.status == 200) {
                    let filename = file.upload.filename;
                    // let year = filename.slice(0, 4);
                    this.$refs.UploadComponentDropzone.removeFile(file);
                    let message =
                      "Arquivo de " + filename + " enviado com sucesso";
                    this.loadFileList();
                    // return HelperService.callMakeToast(message, "success");
                    this.$toast.open({
                      message: message,
                      type: "success",
                      duration: 2000,
                    });
                  }
                })
                .catch((error) => {
                  this.loadingSpinner = false;
                  this.$refs.UploadComponentDropzone.removeFile(file);
                  if (error.response.data != null) {
                    // HelperService.callMakeToast(error.response.data.mensagem[0],"error");
                    this.$toast.open({
                      message: error.response.data.mensagem[0],
                      type: "error",
                      duration: 4000,
                    });
                  } else {
                    // HelperService.callMakeToast("Erro ao enviar", "error");
                    this.$toast.open({
                      message: "Erro ao enviar",
                      type: "error",
                      duration: 4000,
                    });
                  }
                  this.callModalIfExits();
                });
            } else {
              // this.$refs.UploadComponentDropzone.removeFile(file);
              // this.callModalIfExits();
              this.loadingSpinner = true;
              this.$refs.UploadComponentDropzone.removeFile(file);
              this.$refs.UploadComponentDropzone.manuallyAddFile(file, "");
              this.callModalIfExits();
              UploadInssService.uploadFiles(
                file,
                this.tese,
                this.nCodOp,
                this.paramsImportFiles.ajustarPeriodo, // caso queira calcular periodo maior que 60 meses
                true, //valida prefeitura
                false, //  defini se o calculo é RPPS quando clicar em SIM
                this.paramsImportFiles.cutManad, //verifica se é para cortar manad ou nao
                this.cortaManad // lista de periodos para calcular
              )
                .then((response) => {
                  this.loadingSpinner = false;
                  if (response.status == 200) {
                    let filename = file.upload.filename;
                    // let year = filename.slice(0, 4);
                    this.$refs.UploadComponentDropzone.removeFile(file);
                    let message =
                      "Arquivo de " + filename + " enviado com sucesso";
                    this.loadFileList();
                    // return HelperService.callMakeToast(message, "success");
                    this.$toast.open({
                      message: message,
                      type: "success",
                      duration: 2000,
                    });
                  }
                })
                .catch((error) => {
                  this.loadingSpinner = false;
                  this.$refs.UploadComponentDropzone.removeFile(file);
                  if (error.response.data != null) {
                    // HelperService.callMakeToast(error.response.data.mensagem[0],"error");
                    this.$toast.open({
                      message: error.response.data.mensagem[0],
                      type: "error",
                      duration: 4000,
                    });
                  } else {
                    // HelperService.callMakeToast("Erro ao enviar", "error");
                    this.$toast.open({
                      message: "Erro ao enviar",
                      type: "error",
                      duration: 4000,
                    });
                  }
                  this.callModalIfExits();
                });
            }
          });
        }
      } else if (error == "isCutManad") {
        if (this.isOpen) {
          this.toOpenList.file.push(file);
          this.toOpenList.response.push(response);
        } else {
          this.isOpen = true;
          let filename = file.upload.filename;
          this.tablebusyCut = false;
          this.openModalCortarManad();
        }
      } else if (error == "duplicado") {
        this.showMsgBox();
        console.log("arquivo ja existe");
        this.$refs.UploadComponentDropzone.removeFile(this.FileUpload);
      } else {
        // this.$refs.UploadComponentDropzone.removeFile(file);
        this.$refs.UploadComponentDropzone.removeFile(this.FileUpload);
        // return HelperService.callMakeToast(error, "error");
        this.$toast.open({ message: error, type: "error", duration: 4000 });
      }
    },

    handleCompletedQueue() {
      this.yearsAdded = [];
    },

    handleFileAdded(file) {
      this.FileUpload = file;
      this.dropzoneOptions.params = {
        NCodOp: this.nCodOp,
        AjustarPeriodo: this.paramsImportFiles.ajustarPeriodo,
        ValidaPrefeitura: false,
        CutManad: this.paramsImportFiles.cutManad,
        periodos: this.cortaManad,
      };

      // this.dropzoneOptions.send;
    },

    callModalIfExits() {
      this.isOpen = false;
      try {
        if (this.toOpenList.file.length > 0) {
          var responseObj = JSON.parse(
            JSON.stringify(this.toOpenList.response[0])
          );
          this.handleUploadError(this.toOpenList.file[0], responseObj, null);
          this.toOpenList.response.splice(0, 1);
          this.toOpenList.file.splice(0, 1);
        }
      } catch (error) {
        // return HelperService.callMakeToast(error, "error");
        this.$toast.open({ message: error, type: "error", duration: 4000 });
      }
    },

    openModalCortarManad() {
      this.$refs["CortarManad"].show();
    },

    getNcodOp() {},

    sendPeriodManad() {
      this.cortaManad = this.periodosAddOrRemove.listAdicionados;
      this.loadingSpinner = true;
      this.callModalIfExits();

      UploadInssService.uploadFiles(
        this.FileUpload,
        this.tese,
        this.nCodOp,
        this.paramsImportFiles.ajustarPeriodo, // caso queira calcular periodo maior que 60 meses
        false, //valida prefeitura mudar para Variavel
        false, // mudar para Variavel defini se o calculo é RPPS quando clicar em SIM
        (this.paramsImportFiles.cutManad = false), //verifica se é para cortar manad ou nao
        this.cortaManad
      )
        .then((response) => {
          this.loadingSpinner = false;
          if (response.status == 200) {
            // let filename = file.upload.filename;
            let filename = this.FileUpload.upload.filename;
            // let year = filename.slice(0, 4);
            this.$refs.UploadComponentDropzone.removeFile(this.FileUpload);
            let message = "Arquivo de " + filename + " enviado com sucesso";
            this.loadFileList();
            this.$refs["CortarManad"].hide();
            // return HelperService.callMakeToast(message, "success");
            this.$toast.open({
              message: message,
              type: "success",
              duration: 2000,
            });
          }
        })
        .catch((error) => {
          this.loadingSpinner = false;
          this.$refs.UploadComponentDropzone.removeFile(this.FileUpload);
          if (error.response.data != null) {
            // HelperService.callMakeToast(error.response.data.mensagem[0], "error");
            this.$toast.open({
              message: error.response.data.mensagem[0],
              type: "error",
              duration: 4000,
            });
          } else {
            // HelperService.callMakeToast("Erro ao enviar", "error");
            this.$toast.open({
              message: "Erro ao enviar",
              type: "success",
              duration: 2000,
            });
          }
          this.callModalIfExits();
        });
    },

    validateParamsImportFiles() {
      this.paramsImportFiles.ajustarPeriodo =
        this.paramsImportFiles.ajustarPeriodo == undefined
          ? false
          : this.paramsImportFiles.ajustarPeriodo;
      this.paramsImportFiles.cutManad =
        this.paramsImportFiles.cutManad == undefined
          ? false
          : this.paramsImportFiles.cutManad;
    },

    showMsgBox() {
      this.boxUpload = "";
      this.$bvModal
        .msgBoxOk("Já existe manad com este periodo", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        })
        .then((value) => {
          this.boxUpload = value;
        })
        .catch((err) => {
          // An error occurred
        });
    },
  },
};
</script>

<style scoped>
#uploadComponent {
  border: 2px dashed #aaa !important;
  color: #aaa;
  background-color: #eee !important;
  width: 100% !important;
}

.toast-error-tj {
  background-color: aqua !important;
}

VueDropzone .dz-preview .dz-details {
  background-color: #fff !important;
  color: black !important;
}

.btn-tj-blue {
  color: #233873 !important;
  border: 1px solid #233873 !important;
}

.btn-tj-blue:hover {
  background-color: #233873 !important;
  color: #fff !important;
}

#customdropzone {
  background-color: orange;
  font-family: "Arial", sans-serif;
  letter-spacing: 0.2px;
  color: #777;
  transition: background-color 0.2s linear;
  height: 200px;
  padding: 40px;
}

#customdropzone .dz-preview {
  width: 160px;
  display: inline-block;
}

#customdropzone .dz-preview .dz-image {
  width: 80px;
  height: 80px;
  margin-left: 40px;
  margin-bottom: 10px;
}

#customdropzone .dz-preview .dz-image > div {
  width: inherit;
  height: inherit;
  border-radius: 50%;
  background-size: contain;
}

#customdropzone .dz-preview .dz-image > img {
  width: 100%;
}

#customdropzone .dz-preview .dz-details {
  color: white;
  transition: opacity 0.2s linear;
  text-align: center;
}

#customdropzone .dz-success-mark,
.dz-error-mark,
.dz-remove {
  display: none;
}
</style>
