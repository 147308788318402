<template>
  <section class="mt-5">
    <h4 style="float: left" class="text-secondary mb-3">
      Arquivos já enviados.
    </h4>

    <table class="table table-hover">
      <thead>
        <tr>
          <!-- <th><strong>Id</strong></th> -->
          <th><strong>Nome Empresa</strong></th>
          <th><strong>Periodo</strong></th>
          <!-- <th><strong>Ano</strong></th> -->
          <th>
            <strong>CNPJ</strong>
          </th>
          <th>
            <strong>Data de Upload</strong>
          </th>
          <th scope="col">Ação</th>
        </tr>
      </thead>
      <tbody>
        <tr
          style="cursor: pointer"
          v-for="(file, index) in listFiles"
          :key="index"
          :id="`insumo${index}`"
          @click="rowClick(file)"
        >
          <!-- <td>
                        {{ file.id }}
                    </td> -->

          <td>
            <!-- {{ file.nomeContribuinte }} -->
            {{ file.nomeArquivo }}
          </td>

          <td>
            {{ file.dataInicialArquivo }}
          </td>

          <!-- <td>
                        {{ file.dataFinalArquivo }}
                    </td> -->

          <td>
            {{ maskCnpj(file.cnpj) }}
          </td>

          <td>
            {{ formatDate(file.dataHoraCriacaoRegistro) }}
          </td>
          <td>
            <button
              class="btn btn-outline-blue"
              id="habilitarComp"
              @click.prevent="getCompensacao(file)"
              :disabled="file.compensacao == 0"
            >
              Compensação
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- <div class="text-center mt-5">
            <button :disabled="false" @click.stop.prevent="apagarTudo()" class="btn btn-outline-danger">
                Apagar todos os arquivos enviados
            </button>                        
        </div> -->
  </section>
</template>

<script>
import HelperService from "@/services/helper.service.js";
import UploadRestituicaoService from "@/services/uploadRestituicao.service";

export default {
  name: "ListInssUploadFiles",
  props: { listFiles: Array, nCodOp: String, hasExSended: Boolean },

  data() {
    return {
      nCodOp: null,
    };
  },

  mounted() {
    this.nCodOp = this.$route.params.nCodOp;
  },

  methods: {
    // apagarTudo() {
    //        this.$swal({
    //         icon: "error",
    //         title: 'Deseja apagar TODOS os arquivos enviados ?',
    //         html: `Essa ação não poderá ser desfeita, todos os arquivos vão ser apagados.`,
    //         type: 'error',
    //         showCancelButton: true,
    //         confirmButtonColor: '#3085d6',
    //         cancelButtonColor: '#d33',
    //         confirmButtonText: 'Sim, desejo continuar!',
    //         cancelButtonText: `Cancelar`,
    //     })
    //         .then((result) => {
    //             if (result.value) {
    //                 this.$emit("showOverlay", true);
    //                     UploadRestituicaoService.apagarTudo(
    //                     this.nCodOp,
    //                 ).then((response) => {
    //                     if (response.status == 200) {
    //                         this.listFiles = []
    //                         this.$emit("cleanList");
    //                         // HelperService.callMakeToast("Arquivos apagados com sucesso !!", "success");
    //                         this.$toast.open({message: 'Arquivos apagados com sucesso !!', type: 'success', duration: 2000});
    //                         return this.$emit("showOverlay", false);
    //                     }
    //                 }).catch((error) => {
    //                     this.$emit("showOverlay", false);
    //                     console.log(error.response);
    //                     if (error.response.data.mensagem != null) {
    //                         return HelperService.callMakeModalWithBtnsAndTitle("Erro ao apagar !", error.response.data.mensagem[0], 'error');
    //                     }
    //                     // return HelperService.callMakeToast("Erro ao apagar", "error");
    //                     this.$toast.open({message: 'Erro ao apagar', type: 'error', duration: 2000});
    //                 })
    //             }
    //         })
    // },

    maskCnpj(cnpj) {
      return HelperService.maskCnpj(cnpj);
    },

    formatDate(date) {
      return HelperService.formatDate(date);
    },

    rowClick(file) {
      event.stopPropagation();
      this.$router.push(`/manadRubricaPesquisa/${this.nCodOp}/${file.cnpj}`);
    },

    getCompensacao(file) {
      event.stopPropagation();
      let nCodOp = this.$route.params.nCodOp;
      console.log("cnpj: ", file.cnpj);
      console.log("ncodOp: ", nCodOp);

      console.log("Compensação");
    },
  },
};
</script>

<style scoped>
.colored-toast .swal2-icon-success {
  background-color: red !important;
}

#habilitarComp:disabled {
  background-color: gray !important;
}
</style>
